import { Colors } from 'constants/Colors'

export default function AlertColor(type = 'warning') {
  const AlertTypes = {
    external_alert: Colors.danger,
    internal_alert: Colors.warning,
    plot_alert: Colors.warning,
    warning: Colors.warning,
    connection_alert: Colors.green,
    disconnect_alert: Colors.danger,
    zero_steps_alert: Colors.warning,
    possible_illness: Colors.danger,
    possible_birth: Colors.warning,
  }

  return AlertTypes[type]
}
