import React, { useCallback, useState } from 'react'
import { infoModal } from 'components/Modal/infoModal'
import { Divider, Form, Row, Col, message, Typography } from 'antd'
import { Translate } from 'lang'
import { lowerCase } from 'lodash'
import axiosInstance from 'store/services/axiosConfig'
import { AlertForm } from './Types/alert'
import { RadioForm } from './Types/radio'
import { TitleForm } from './Types/title'
import { TextForm } from './Types/text'
import { TextAreaForm } from './Types/textarea'
import { DateForm } from './Types/date'
import { SelectForm } from './Types/select'
import { SwitchForm } from './Types/switch'
import { FooterForm } from './Types/footer'
import { EmailForm } from './Types/email'
import { PhoneForm } from './Types/phone'
import { NumberForm } from './Types/number'
import { CpfCnpjForm } from './Types/cpf_cnpj'
import { LocalizationForm } from './Types/localization'
import { MapsForm } from './Types/maps'
import { ColorForm } from './Types/color'
import { Block } from './Types/block'
import { DrawingForm } from './Types/drawing'
import { TimeForm } from './Types/time'
import { PasswordForm } from './Types/password'
import { CheckboxForm } from './Types/checkbox'
import { SearchForm } from './Types/search'
import { FileForm } from './Types/file'
import MapDrawingForm from './Types/map_drawing_structure'

const { Title } = Typography

export function getInput(data, initialValues, form, allValues) {
  return data.map((input, index) => {
    const rest = {
      key: `${input?.name}-${index}`,
      form,
      allValues,
    }
    switch (input?.type) {
      case 'color':
        return <ColorForm value={input} {...rest} initialValues={initialValues?.color} />
      case 'text':
        return <TextForm value={input} {...rest} />
      case 'password':
        return <PasswordForm value={input} {...rest} />
      case 'textarea':
        return <TextAreaForm value={input} {...rest} />
      case 'date':
        return <DateForm value={input} {...rest} />
      case 'time':
        return <TimeForm value={input} {...rest} />
      case 'email':
        return <EmailForm value={input} {...rest} />
      case 'alert':
        return <AlertForm value={input} {...rest} />
      case 'phone':
        return <PhoneForm value={input} {...rest} />
      case 'select':
        return <SelectForm value={input} {...rest} />
      case 'title':
        return <TitleForm value={input} {...rest} />
      case 'radio':
        return <RadioForm value={input} {...rest} />
      case 'checkbox':
        return <CheckboxForm value={input} {...rest} />
      case 'switch':
        return <SwitchForm value={input} {...rest} />
      case 'search':
        return <SearchForm value={input} {...rest} />
      case 'cpf-cnpj':
        return <CpfCnpjForm value={input} {...rest} />
      case 'number':
        return <NumberForm value={input} {...rest} />
      case 'localization':
        return (
          <LocalizationForm value={input} {...rest} initialValues={initialValues?.localization} />
        )
      case 'maps':
        return <MapsForm value={input} {...rest} initialValues={initialValues} />
      case 'drawing-map':
        return <DrawingForm value={input} {...rest} initialValues={initialValues} />
      /* EXTRA */
      case 'footer':
        return <FooterForm value={input} {...rest} />
      case 'divider':
        return <Divider key={rest.key} />
      case 'break':
        return <Col xs={24} key={rest.key} style={input.style} />
      /* MULTIPLE INPUTS INSIDE ONE COLUMN */
      case 'block':
        return <Block value={input} {...rest} initialValues={initialValues} />
      case 'file':
        return <FileForm value={input} {...rest} initialValues={initialValues} />
      case 'structure-map':
        return <MapDrawingForm value={input} {...rest} initialValues={initialValues} />
      default:
        return null
    }
  })
}
export default function FormBuilder(props) {
  const {
    name,
    controller,
    data,
    title,
    validateMessages,
    rowGap = 8,
    columnGap = 16,
    options,
  } = props
  const { initialValues, manipulateValues, method, messages, afterOk } = props
  const [form] = Form.useForm()
  const [allValues, setAllValues] = useState(initialValues)

  const onSubmit = useCallback(async values => {
    let formatedValues = values
    if (lowerCase(method) === 'delete') {
      formatedValues = values ? { data: values } : null
    }

    if (manipulateValues) {
      formatedValues = manipulateValues(values)
    }
    let data
    let code
    let status = 'loading'

    try {
      const response = await axiosInstance[lowerCase(method)](controller, formatedValues, options)
      data = response.data
      code = response.code
      status = response.status
    } catch (err) {
      console.warn('unknown error')
    }
    if (code === 200 || code === 204) {
      message.success(messages?.success ?? Translate({ messageKey: 'success_message' }))
      afterOk(values)
    } else {
      infoModal(data, status, code, afterOk, messages)
    }
  }, [])

  return !data ? null : (
    <>
      <Title level={3}>{title}</Title>
      <Form
        form={form}
        id={name}
        name={name}
        layout={'vertical'}
        onFinish={onSubmit}
        initialValues={initialValues}
        validateMessages={validateMessages}
        onValuesChange={(_, allValues) => setAllValues(allValues)}
      >
        <Row gutter={[columnGap, rowGap]}>{getInput(data, initialValues, form, allValues)}</Row>
      </Form>
    </>
  )
}

FormBuilder.defaultProps = {
  method: 'POST',
  validateMessages: {
    required: Translate({ messageKey: 'required' }),
  },
}
