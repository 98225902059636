import { Form, Col, InputNumber } from 'antd'
import { validateField } from 'components/FormBuilder/assets/validate'
import { Translate } from 'lang'
import { parseInt } from 'lodash'
import toLower from 'lodash/toLower'
import { useEffect, useState } from 'react'

const style = {
  form: {
    display: 'inline-block',
    width: '100%',
  },
  input: {
    width: '100%',
  },
}

export const NumberForm = ({ value, allValues }) => {
  const {
    name,
    label,
    disabled,
    rules = [],
    placeholder,
    cols = { xs: 24 },
    condition = () => true,
    decimals,
  } = value

  const [enabled, setEnabled] = useState(true)
  const [required, setRequired] = useState(false)

  useEffect(() => {
    const isEnabled = !disabled && condition(allValues)
    const isRequired = validateField(allValues, value.required)
    setEnabled(isEnabled)
    setRequired(isRequired && isEnabled)
  }, [allValues, setEnabled, setRequired])

  const defaultPlaceholder = Translate({
    messageKey: 'inform',
    gender: 'male',
    items: {
      item: toLower(label),
    },
  })
  const parser = value => {
    if (!decimals) return value
    return parseInt(value.toString().replace('.', ''))
  }

  const formatter = value => {
    if (!decimals) return value

    const regex = new RegExp(`(\\d)(\\d{${decimals}})$`)
    return value.replace(regex, '$1.$2')
  }

  return (
    <Col {...cols}>
      <Form.Item name={name} label={label} rules={[...rules, { required }]} style={style.form}>
        <InputNumber
          placeholder={placeholder ?? defaultPlaceholder}
          formatter={formatter}
          parser={parser}
          disabled={!enabled}
          autoComplete="off"
          controls={false}
          style={style.input}
        />
      </Form.Item>
    </Col>
  )
}
