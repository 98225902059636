import React from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/Colors'
import AlertIcons from 'components/Notifications/Manipulate/AlertIcons'
import { CheckOutlined } from '@ant-design/icons'

import AlertColor from 'components/Notifications/Manipulate/AlertColor'
import { Row } from 'antd'

const StyledDescription = styled(`p`)`
  color: #455560;
  margin-bottom: 5px;
  line-height: 20px !important;
`
const StyledTitle = styled(`p`)`
  color: #333333 !important;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 20px !important;
  margin-right: 0.5rem;
`
const StyledTimestamp = styled(`p`)`
  color: #999999 !important;
  margin: 0;
  font-size: 12px;
`
const Box = styled(`div`)`
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  padding: 15px 20px;
  width: 100%;
  cursor: pointer;
`
const IconBox = styled(`div`)`
  display: flex;
`

export default function NotificationAlert({
  title,
  body,
  time,
  is_read,
  type = 'warning',
  withBackground = true,
  onClick,
  is_answered,
}) {
  const color = AlertColor(type)

  const alertStyle = {
    border: `1px solid ${is_read ? Colors.gray_medium : color}99`,
    backgroundColor: `${is_read ? Colors.gray_medium : color}1a`,
  }

  return (
    <>
      <Box style={withBackground ? alertStyle : { padding: 0 }} onClick={onClick}>
        <IconBox>
          <AlertIcons type={type} />
        </IconBox>
        <div>
          <Row>
            <StyledTitle>{title}</StyledTitle>
            {is_answered ? <CheckOutlined style={{ color: Colors.green }} /> : null}
          </Row>
          <StyledDescription>{body}</StyledDescription>
          <StyledTimestamp>{time}</StyledTimestamp>
        </div>
      </Box>
    </>
  )
}
